<template>
  <div>
    <div class="property">
      <div class="label">
        {{ $t('process_editor.element_properties.user_task.target_registry_id') }}
      </div>
      <registry-select-tree
        type="registry"
        :value="{id: parseInt(extensions.target_registry_id) || null}"
        @change="onExtensionChangeWithAlias('target_registry_id', $event)"
      />
    </div>
    <div class="property" v-if="extensions.target_registry_id">
      <div class="label">
        {{ $t('logic_editor_v2.element_types.command') }}
      </div>
      <logic-tree-elements
        :key="extensions.target_registry_id || 0"
        :value="parseInt(extensions.command_id) || null"
        :placeholder="$t('logic_editor_v2.element_types.command')"
        :object-id="parseInt(extensions.target_registry_id) || null"
        @input="$emit('change-extension', { name: 'command_id', value: $event })"
        element-type="command"/>
    </div>
    <div class="property">
      <div class="label">
        {{ $t('logic_editor_v2.state_field_types.parameter') }}
      </div>
      <el-input size="mini" :value="extensions['record.value']" @input="changeRecordGetter"></el-input>
    </div>
  </div>
</template>

<script>
import mixins from '../../mixins'
import RegistrySelectTree from '@/core/infrastructure/components/RegistrySelectTree.vue'

export default {
  name: 'ExecuteCommand',
  components: { RegistrySelectTree },
  mixins: [mixins],
  methods: {
    changeRecordGetter (value) {
      this.$emit('change-extension', { name: 'record.value', value: value })
      this.$emit('change-extension', { name: 'record.type', value: 'parameter' })
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../../../main";
</style>
